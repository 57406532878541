<template>
  <b-card class="w-100 mb-4" no-body>
    <div class="p-35">
      <div class="d-flex align-items-start">
        <div>
          <h4 class="card-title mb-1">Add Calendar</h4>
          <b-card-text class="text-muted"> </b-card-text>
        </div>
      </div>
    </div>
    <hr class="m-0" />
    <b-card-body>
      <b-row>
        <b-col sm="1"></b-col>
        <b-col sm="3">
          <b-form-group label="Calendar Type">
            <b-form-radio v-model="calType"  name="some-radios" value="A">InDefinite</b-form-radio>
            <b-form-radio v-model="calType"  name="some-radios" value="B">Date Range</b-form-radio>
            <b-form-radio v-model="calType"  name="some-radios" value="C">Custom</b-form-radio>
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <label class="fw-medium">Calendar Title</label>
          <b-form-group>
          <b-form-input
            id="name"
            type="text"
            placeholder="Calendar Name"
            v-model="$v.form.name.$model"
                            :state="validateState('name')"
            required
          ></b-form-input>
          <b-form-invalid-feedback id="name"
                            >This is a required field</b-form-invalid-feedback
                          >
          </b-form-group>
        </b-col>
        <b-col sm="2">
          <label class="fw-medium">Status</label>
          <b-select
            v-model="form.status"
            :options="statuses"
            required
          ></b-select>
        </b-col>
      </b-row>
      <b-row v-if="calType === 'A'" class="mt-4">
          <b-col sm="1"></b-col>
          <b-col sm="3">
            <label class="fw-medium">Start Date</label>
            <b-form-datepicker v-model="form.start_date" required></b-form-datepicker>
          </b-col>
          <b-col sm="2">
            <label class="fw-medium">Weekly Holiday</label>
            <b-select
              v-model="weeklyOff"
              :options="weeklyOffDays"
              required
            ></b-select>
          </b-col>
      </b-row>
      <b-row v-if="calType === 'B'" class="mt-4">
          <b-col sm="1"></b-col>
          <b-col sm="3">
            <label class="fw-medium">Start Date</label>
            <b-form-datepicker v-model="form.start_date" required></b-form-datepicker>
          </b-col>
          <b-col sm="3">
            <label class="fw-medium">End Date</label>
            <b-form-datepicker v-model="form.end_date"></b-form-datepicker>
          </b-col> 
          <b-col sm="2">
            <label class="fw-medium">Weekly Holiday</label>
            <b-select
              v-model="weeklyOff"
              :options="weeklyOffDays"
              required
            ></b-select>
          </b-col>
      </b-row>
      <b-row v-if="calType === 'C'" class="mt-4">
          <b-col sm="1"></b-col>
          <b-col sm="3">
            <label class="fw-medium">Add Operational Dates</label>
            <b-form-datepicker v-model="cdate" required></b-form-datepicker>
            <b-button @click="addDate()">Add</b-button>
          </b-col>
          <b-col sm="8">
            <b-badge v-for="date in form.customdays" :key="date" class="mr-1" variant="info">{{ date }}</b-badge>
          </b-col> 
      </b-row>
      <b-row class="mt-4">
          <b-col sm="1"></b-col>
          <b-col sm="3">
            <label class="fw-medium">Holiday Dates (If Any)</label>
            <b-form-datepicker v-model="holiday" required></b-form-datepicker>
            <b-button @click="addHolidate()">Add</b-button>
          </b-col>
          <b-col sm="8">
            <b-badge v-for="date in form.holidays" :key="date" class="mr-1" variant="info">{{ date }}</b-badge>
          </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col class="d-flex justify-content-end">
          <b-button pill variant="primary" class="px-4" @click="saveCal()"
            >Save</b-button
          >
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { validationMixin } from "vuelidate";
import {
  required,
  maxLength,
} from "vuelidate/lib/validators";
export default {
  name: "Update_Calendar",
  mixins: [validationMixin],
  data: () => ({
    form: {
      name: null,
      start_date: null,
      end_date: null,
      holidays: [],
      weeklyoffdays: [],
      customdays: [],
      status: 1,
      user_id: null
    },
    statuses: [
      { value: 1, text: "Active" },
      { value: 2, text: "In-Active" },
    ],
    holiday: null,
    cDate: null,
    calType: null,
    weeklyOff: null,
    weeklyOffDays: [
      { value: 1, text: "Monday" },
      { value: 2, text: "Tuesday" },
      { value: 3, text: "Wednesday" },
      { value: 4, text: "Thursday" },
      { value: 5, text: "Friday" },
      { value: 6, text: "Saturday" },
      { value: 7, text: "Sunday" },
    ],
  }),
  computed: {
    ...mapState({
      authId: (state) => state.auth.authId
    })
  },
  mounted() {
    this.initComp();
  },
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(20),
      },
      start_date:{
        required,
      },
      end_date:{
        required,
      }
    },
  },
  methods: {
    ...mapActions(["getCalendar","saveCalendar"]),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
      },
    initComp() {
      this.getCalendar(this.$route.params.id).then((res) =>{
        //this.form = res[0];
        res[0].weekly_off.forEach(element => {
          this.weeklyOff = element.week_day;
        });
        res[0].holidays.forEach(element => {
          this.form.holidays.push(element.dated);
        });
        this.form.name = res[0].name;
        this.form.start_date = res[0].start_date;
        this.form.end_date = res[0].end_date;
        if(res[0].is_custom !== "0"){
          this.calType = "C"
        }
        else if(this.form.end_date === "2022-12-31"){
          this.calType = "A"
        }
        else{
          this.calType = "B"
        }
      })
    },
    addHolidate(){
      this.form.holidays.push(this.holiday);
    },
    addDate(){
      this.form.customdays.push(this.cDate);
    },
    updateCal() {
      this.form.user_id = this.authId;
      this.form.weeklyoffdays.push(this.weeklyOff);
      if(!this.form.end_date) { this.form.end_date = "2022-12-31" }
      //console.log(this.form);
      this.saveCalendar(this.form).then((res) => {
        console.log("Added Succesfully", res);
      });
    },
  },
};
</script>